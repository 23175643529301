import React from 'react';
import styled from 'styled-components';
import customMedia from '../components/customMedia';

const Container = (props) => {
  const { margin, cursor, width, height, children, bgColor, maxWidth, center, mainFlex } = props;
  const styles = { margin, cursor, width, height, bgColor, maxWidth, center, mainFlex };
  return (
    <React.Fragment>
      <ElContainer {...styles}>{children}</ElContainer>
    </React.Fragment>
  );
};

Container.defaultProps = {
  children: null,
  margin: '',
  cursor: '',
  center: '',
  width: '',
  height: '',
  bgColor: '',
  mainFlex: '',
};

const ElContainer = styled.div`
  max-width: ${(props) => props.maxWidth};
  width: ${(props) => props.width};
  height: ${(props)=> props.height};
  margin: ${(props) => props.margin};
  cursor: ${(props) => props.cursor};
  background-color: ${(props) => props.bgColor};
  ${(props) => (props.center ? `text-align: center;` : '')};
  ${(props) =>
    props.mainFlex ? `display : flex; align-items: flex-start;` : ''};

    ${customMedia.lessThan('uhd')`
      height : 2160px;
      justify-content: center;
    `}

    ${customMedia.lessThan('qhd')`
    height : 1440px;
    justify-content: center;
  `}

    ${customMedia.lessThan('hd')`
    height : 1080px;
    justify-content: center;
    `}
    
    ${customMedia.lessThan('wxga')`
    height : 768px;
    justify-content: center;
    `}
    
    ${customMedia.lessThan('tabletGalaxy')`
    height : 800px;
    justify-content: center;
    `}
    

    ${customMedia.lessThan('tablet')`
    min-height : 820px;
    justify-content: center;
    `}

    ${customMedia.lessThan('tabletMini')`
    min-height : 768px;
    justify-content: center;
    `}





    `;

export default Container;