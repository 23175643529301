import React from "react";
import Plot from 'react-plotly.js'
import { Grid } from "../../elements";

const Co2Form = ({co2Data, layout}) => {

    let now_width = window.innerWidth;
    let now_height = window.innerHeight;
    let plot_size_height;

    if(now_width === 2220 && now_height === 1080) {
        plot_size_height = '18.5vh'
    } else {
        plot_size_height = '19.5vh'
    }

    return(
        <Grid width='100%' height='100%'  bgColor ='rgb(23,23,23)' borderRadius= '12px' inlineCenter>
            <Plot 
                data={co2Data}
                layout={layout}
                config={{
                    responsive : true,
                    displayModeBar : false
                }}
                
                style = {{width: "95%", height: plot_size_height, useResizeHandler: true, margin: '0 auto'}}
            />
        </Grid>
    )

}

export default Co2Form;