import axios from 'axios';
// import { history } from '../redux/configureStore';

axios.defaults.withCredentials = true;

const instance = axios.create({
  // 서버 주소
  //baseURL: 'http://3.39.4.133/api/airquality',
  //baseURL: 'https://2dias3im3b.execute-api.ap-northeast-2.amazonaws.com/dev/airquality',
  baseURL: 'https://2dias3im3b.execute-api.ap-northeast-2.amazonaws.com/dev',
  headers: {
    'content-type': 'application/json;charset=UTF-8',
    accept: 'application/json',
  },
});

instance.interceptors.request.use(
  (config) => {
    // cookie or token
    return config;
  },
  (err) => {
    console.log(err);
  },
);

const instances = axios.create({
  // 서버 주소
  baseURL: 'http://192.168.1.37',
  headers: {
    'content-type': 'application/json;charset=UTF-8',
    accept: 'application/json',
  },
});

instances.interceptors.request.use(
  (config) => {
    // cookie or token
    return config;
  },
  (err) => {
    console.log(err);
  },
);

const instanceMyondo = axios.create({
  // 서버 주소
  baseURL: 'http://192.168.1.37/api/myondo/',
  headers: {
    'content-type': 'application/json;charset=UTF-8',
    accept: 'application/json',
  },
});

instanceMyondo.interceptors.request.use(
  (config) => {
    // cookie or token
    return config;
  },
  (err) => {
    console.log(err);
  },
);



export const apis = {
  // login page
  LoginAX: (user_id, user_password) =>
    instances.post('/login/', {
      user_id: user_id,
      user_password: user_password,
    }),
    
  // Dashboard data
   //getDashboardAX: () => instance.get('/1/'),
   //getDashboard02AX: () => instance.get('/2/'),
   //getDashboard03AX: () => instance.get('/3/'),

  getDashboardAX: () => instance.get('/airquality'),
  getDashboard02AX: () => instance.get('/airquality'),
  getDashboard03AX: () => instance.get('/airquality'),
  //getMyondoAX: () => instanceMyondo.get('/')
};
