import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Container, Grid, Text, Image } from '../elements';
import { dataCreators } from '../redux/modules/dashboard';
import dayjs from 'dayjs'

import PersonGraphDetail from '../containers/PersonGraphDetail';
import EnergyBarDetail from '../containers/EnergyBarDetail';
import PersonHistoryDetail from '../containers/PersonHistoryDetail';

import today from '../assets/Icon/today.png'
import cool_on_icon from '../assets/Icon/cooling_on_icon.png'
import heating_off_icon from '../assets/Icon/heating_off_icon.png'
import cool_off_icon from'../assets/Icon/cooling_of_icon.png'
import heating_on_icon_back from '../assets/Icon/heating_on_back_icon.png'
import heating_on_icon from '../assets/Icon/heating_on_Icon.png'
import aski from '../assets/Icon/aski.png'
;

const MyondoMainForm = () => {
    const dispatch = useDispatch();

    const myondoData = useSelector(
        (state) => state.data.myondo
    )

    const presence = myondoData?.data?.presence;
    const aircon_uptime = myondoData?.data?.aircon_uptime;

    // 현제 에어컨 동작 모드를 나타내는 코드
    let now_aircon_type =aircon_uptime?.sub_mode;

    // 금일 총 가동시간의 달력이미지 날짜 구하는 코드(현재 날짜)
    let date = presence?.start_time
    let day = dayjs(date).format('DD');


    // 현재 시간 값
    let now_time = dayjs();

    // 예측 시간 값
    let switch_time = dayjs(myondoData?.data?.switch_prediction.switch_time);

    // 예측시간 - 현재시간 값(minute 단위)
    let hour_gap = switch_time.diff(now_time, 'hour');
    let min_gap = switch_time.diff(now_time, 'minute') - hour_gap * 60 ;

    // 재실 예측값
    let switch_percentage = myondoData?.data?.switch_prediction.switch_percentage * 100;

    //재실 여부
    const is_comming = myondoData?.data?.switch_prediction.is_coming;


    React.useEffect(() => {
        dispatch(dataCreators.getMyondoMW());
    
        let now_width = window.innerWidth;
        if (now_width <= 1040) {
            alert('현재 해상도 이하로 화면이 고르지 못함을 알려드립니다.');
        }
    }, [dispatch]);


return (
    <React.Fragment>
        <Container width='100%' height='100%' bgColor='#262626' mainFlex>
            <Grid width='calc( 100% - 39%)' height='100%'>
                <Grid height='3vw' margin='6vw 0 0 12.7vw' >
                    <Text size='2.5vw' bold='700' color='#C4C4C4'>
                        공간학습 기반 재실 예측
                    </Text>
                </Grid>
                <Grid height="72%" margin="3vw 0 0 5.5vw" flexCenter float="left" >
                    <Grid width="40vw" height="33.7vw" margin="0 0.8vw 0 0" >
                        <Grid bgColor='#171717' height="6.04vw" borderRadius='1vw'>
                            {is_comming === 0 ? (
                                <Text size='1.87vw' bold='600' color='#D46BF9' padding='1.8vw 3.2vw 1.3vw 3.2vw' align="center" fontFamily='Poppins'>
                                    {hour_gap}시간 {min_gap}분 후 {switch_percentage}% 확률로 퇴실 예정
                                </Text>
                            ) :
                            is_comming === 1 ? (
                                <Text size='1.87vw' bold='600' color='#D46BF9' padding='1.8vw 6.5vw 1.3vw 6.5vw' align="center" fontFamily='Poppins'>
                                    {hour_gap}시간 {min_gap}분 후 {switch_percentage}% 확률로 재실 예정
                                </Text>
                            ) : null}
                        </Grid>
                        <PersonGraphDetail presence={presence}/>
                    </Grid>
                    <Grid width="10.88vw" height="35vw" bgColor='#171717' borderRadius="1.04vw" columnCenter>
                        <Grid margin="2.30vw 4.58vw 0.81vw 4.58vw">
                            <Text size="0.72vw" color="#5F5F5F">
                                {day}
                            </Text>
                            <Image 
                                width="1.7vw"
                                height="1.87vw"
                                src={today}
                                position='absolute'
                                top="-0.8vw"
                                left="-0.4vw"
                            />
                        </Grid>
                        <Grid width="7.56vw" height="1.40vw">
                            <Text size="0.93vw" bold="400" align="center" color="#5F5F5F" fontFamily='Poppins'>
                                금일 총 가동시간
                            </Text>
                        </Grid>
                        <Grid width="7.97vw" margin="1.09vw 0 1.4vw 0">
                            <Text size="2.3vw" bold="600" align="center" color="#00A7FF" fontFamily='Poppins'>
                                {aircon_uptime?.aircon_uptime === undefined ?
                                    0 : aircon_uptime?.aircon_uptime
                                }시간
                            </Text>
                        </Grid>
                        <hr width='80%' size='0' color='#5F5F5F' bold='1px' />
                        <Grid width="7.30vw"margin='1.66vw 0 0 0'>
                            <Text size="0.93vw" bold="400" align="center" color="#5F5F5F" fontFamily='Poppins' >
                                에어컨 설정온도
                            </Text>
                        </Grid>
                        <Grid width="5.62vw" height="4vw" margin="1.45vw 0 1.71vw 0">
                            <Text size="3.33vw" bold="500" align="center" color="#00A7FF" fontFamily='Poppins'>
                                {aircon_uptime?.target_temp === undefined ? 
                                    0 : aircon_uptime?.target_temp
                                }°
                            </Text>
                        </Grid>
                        <hr width='80%' size='0' color='#5F5F5F' bold='1px' />
                        <Grid width="9.30vw" margin='1.66vw 0 1.77vw 0'>
                            <Text size="0.92vw" bold="400" align="center" color="#5F5F5F" fontFamily='Poppins'>
                                현재 에어컨 동작 모드
                            </Text>
                        </Grid>
                        {now_aircon_type === 0 ? (
                        <Grid width="10.88vw" mainFlex>
                            <Grid width="2.96vw" margin="0 1.56vw 0 1.71vw" >
                                <Image 
                                    width="2.96vw"
                                    height="2.96vw"
                                    src={cool_on_icon}
                                />
                                <Grid  margin='0.41vw 0 0 0'  >
                                    <Text size="0.62vw" align="center" color="#00A7FF">
                                        Cooling
                                    </Text>    
                                </Grid>
                            </Grid>
                            <Grid width="2.96vw" margin="0 1.66vw 0 0">
                                <Image 
                                    width="2.96vw"
                                    height="2.96vw"
                                    src={heating_off_icon}
                                />
                                <Grid  margin='0.41vw 0 0 0'  >
                                    <Text size="0.62vw" align="center" color="#5F5F5F">
                                        Heating
                                    </Text>    
                                </Grid>
                            </Grid>
                        </Grid>
                        )
                        :
                        now_aircon_type === 1 ? (
                        <Grid width="10.88vw" mainFlex>
                            <Grid width="2.96vw" margin="0 1.56vw 0 1.71vw" >
                                <Image 
                                    width="2.96vw"
                                    height="2.96vw"
                                    src={cool_off_icon}
                                />
                                <Grid  margin='0.41vw 0 0 0'  >
                                    <Text size="0.62vw" align="center" color="#5F5F5F">
                                        Cooling
                                    </Text>    
                                </Grid>
                            </Grid>
                            <Grid width="2.96vw" margin="0 1.66vw 0 0">
                                <Image
                                    position="absolute" 
                                    top='0.5vw'
                                    left="0.5vw"
                                    width="1.96vw"
                                    height="1.96vw"
                                    src={heating_on_icon}
                                />
                                <Image 
                                    width="2.96vw"
                                    height="2.96vw"
                                    src={heating_on_icon_back}
                                />
                                <Grid  margin='0.41vw 0 0 0'  >
                                    <Text size="0.62vw" align="center" color="#FB2F2E">
                                        Heating
                                    </Text>    
                                </Grid>
                            </Grid>
                        </Grid>
                    ) : 
                    now_aircon_type === -1 ? (
                        <Grid width="10.88vw" mainFlex>
                        <Grid width="2.96vw" margin="0 1.56vw 0 1.71vw" >
                            <Image 
                                width="2.96vw"
                                height="2.96vw"
                                src={cool_off_icon}
                            />
                            <Grid  margin='0.41vw 0 0 0'  >
                                <Text size="0.62vw" align="center" color="#5F5F5F">
                                    Cooling
                                </Text>    
                            </Grid>
                        </Grid>
                        <Grid width="2.96vw" margin="0 1.66vw 0 0">
                            <Image 
                                width="2.96vw"
                                height="2.96vw"
                                src={heating_off_icon}
                            />
                            <Grid  margin='0.41vw 0 0 0'  >
                                <Text size="0.62vw" align="center" color="#5F5F5F">
                                    Heating
                                </Text>    
                            </Grid>
                        </Grid>
                    </Grid>  
                    ) : 
                    now_aircon_type === undefined ? (
                        <Grid width="10.88vw" mainFlex>
                        <Grid width="2.96vw" margin="0 1.56vw 0 1.71vw" >
                            <Image 
                                width="2.96vw"
                                height="2.96vw"
                                src={cool_off_icon}
                            />
                            <Grid  margin='0.41vw 0 0 0'  >
                                <Text size="0.62vw" align="center" color="#5F5F5F">
                                    Cooling
                                </Text>    
                            </Grid>
                        </Grid>
                        <Grid width="2.96vw" margin="0 1.66vw 0 0">
                            <Image 
                                width="2.96vw"
                                height="2.96vw"
                                src={heating_off_icon}
                            />
                            <Grid  margin='0.41vw 0 0 0'  >
                                <Text size="0.62vw" align="center" color="#5F5F5F">
                                    Heating
                                </Text>    
                            </Grid>
                        </Grid>
                    </Grid>  
                    ) : null
                    }
                    </Grid>
                </Grid>
            </Grid>
            <Grid width='calc( 100% - 61%)' height='100%' center>
                <Grid width="" margin='12vw 5.52vw 0 0' mainFlex>
                    <Grid width="16.25vw" bgColor='#171717' borderRadius="1vw" margin="0 0.88vw 0 0" >
                        <EnergyBarDetail myondoData={myondoData}/>                        
                    </Grid>
                    <Grid width="16.25vw" bgColor='#171717' borderRadius="1vw"  >
                        <PersonHistoryDetail presence={presence}/>                        
                    </Grid>
                </Grid>
                <Grid margin='3vw 0 3vw 28vw'>
                    <Image
                        width='66px'
                        height='66px'
                        size='66px'
                        src={aski}
                    ></Image>
                </Grid>
            </Grid>
        </Container>
    </React.Fragment>
    );
};

export default MyondoMainForm;
