import React from "react";
import Plot from 'react-plotly.js'
import { Grid } from "../elements";

const PersonHistoryForm = ({personData, layout}) => {
    
    // let now_width = window.innerWidth;
    // let now_height = window.innerHeight;
    // let plot_size_height;

    // if(now_width === 2220 && now_height === 1080) {
    //     plot_size_height = '18.5vh'
    // } else {
    //     plot_size_height = '19.5vh'
    // }

    return(
            <Grid width='100%' height='34.06vw'  margin="0.8vw 0 0 0" bgColor ='rgb(23,23,23)' borderRadius='1vw'   >
                <Plot
                    data={personData}
                    layout={layout}
                    config={{
                        responsive : true,
                        displayModeBar : false
                    }}
                    
                    style = {{width: "97%", height: '33.8vw', useResizeHandler: true, margin:'0 auto' }}
                />
            </Grid>
    )
}

export default PersonHistoryForm;