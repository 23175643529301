import React from 'react';
import Co2Detail from '../containers/lineChartGraph/Co2Detail';
import HumidDetail from '../containers/lineChartGraph/HumidDetail';
import Pm10Detail from '../containers/lineChartGraph/Pm10Detail';
import Pm25Detail from '../containers/lineChartGraph/Pm25Detail';
import TemperatureDetail from '../containers/lineChartGraph/TemperatureDetail';
import TotalScoreDetail from '../containers/lineChartGraph/TotalScoreDetail';
import TvocDetail from '../containers/lineChartGraph/TvocDetail';
import { Grid } from '../elements';

const GraphForm = ({dashboardData}) => {


  return (
    <Grid width='100%' height='100%' bgColor='rgb(38, 38, 38)'>
      <Grid hegiht='100%'>
        <Grid width='100%' height='100%' margin='0 0 1% 0'>
          <TotalScoreDetail dashboardData={dashboardData} />
        </Grid>
        <Grid width='100%' height='100%' margin='0 0 1% 0'>
          <Grid width='49.2%' height='100%' inlineCenter margin='0 1.5% 0 0'>
            <Co2Detail dashboardData={dashboardData} />
          </Grid>
          <Grid width='49.3%' height='100%' inlineCenter>
            <TvocDetail dashboardData={dashboardData} />
          </Grid>
        </Grid>
        <Grid width='100%' height='100%' margin='0 0 1% 0'>
          <Grid width='49.2%' height='100%' inlineCenter margin='0  1.5% 0 0'>
            <Pm25Detail dashboardData={dashboardData} />
          </Grid>
          <Grid width='49.3%' height='100%' inlineCenter>
            <Pm10Detail dashboardData={dashboardData} />
          </Grid>
        </Grid>
        <Grid width='100%' height='100%'>
          <Grid width='49.2%' height='100%' inlineCenter margin='0  1.5% 0 0'>
            <TemperatureDetail dashboardData={dashboardData} />
          </Grid>
          <Grid width='49.3%' height='100%' inlineCenter>
            <HumidDetail dashboardData={dashboardData} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default GraphForm;
