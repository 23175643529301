import React from "react";
import PersonGraphForm from "../components/PersonGraphForm";
import dayjs from "dayjs";

const PersonGraphDetail = ({presence}) => {

    

    // 그래프의 layout 정보를 담는 변수
    let layout
    
    //현재까지의 created_at, total_score의 값과 이후에 예상되는 created_at, total_score의 값 
    let start_time;
    let real_beforeDate;
    let futureDate;
    let beforeGraphScore;
    let afterTotalScore;

    // x축과 y축에 값을 넣어줄 배열
    let xArray = []
    let hour_xArray = []
    let yArray = []  
    let xArray2 = []
    let yArray2 = []
    
    //airquality의 data(값)을 담아줌
    let before_data

    // future의 data(값)을 담아줌
    let future_data

    // future의 마지막 값
    let future_data_last

    // airquality의 마지막 값, 그래프에서 보여질 값을 담아줌
    let airquality_data_point;

        
    //data(x축의 값들과 y축의 값들을 포함한)
    let personData = [];

    // before_data와 future의 길이(갯수)
    let myondo_length = presence?.length;
    // let future_length = dashboardData?.future.length;
    
    // 현재 브라우저의 크기
    let now_width = window.innerWidth;
    
    // 그래프 폰트 size
    let graph_font_size;
    let graph_point_font_size;
    let graph_point_size;
    let label_size;
    let data_line_size;
    let title_font_size;
    let x_time_size;

  
    
    

    //그래프 현재 값 text 위치
    let pointLabel;

    // 현재 시간 구하는 문장
    let focus_now_hour = [];
    let x_only_hour = []
    let from_now_range;

    let percent_y;
    let percent_y_array = [];


    for (let i = 0; i < myondo_length; i++){
        //서버에서 날짜 받아오는 코드
        start_time = presence[i]?.start_time;
        
        // 서버에서 받아온 날짜 형식 바꾸는 코드
        let day = dayjs(start_time).format('HH:mm');
        let hour = dayjs(start_time).format('HH');

        
        // 현재까지의 값과 앞으로 예상되는 값
        beforeGraphScore = presence[i].presence_percentage;
        
        // dayjs 통해 형식을 수정한 값을 배열로 넣어주기 위한 문장(before_data)
        xArray.push(day)
        hour_xArray.push(hour)
        yArray.push(beforeGraphScore);
        
    }

    from_now_range =xArray.splice(xArray.length-4 ,xArray.length-1)
    // x축 라벨 작업을 위한 코드
    x_only_hour = hour_xArray.splice(hour_xArray.length-4, hour_xArray.length-1)

    let x_range = from_now_range.length

    percent_y = yArray.splice(yArray.length-4 , yArray.length-1)
    for(let i = 0; i<=from_now_range.length; i++) {
            percent_y_array.push(percent_y[i] * 100)
            
        }

    // for(let i = 0; i < future_length; i++) {
    //     //미래(future) 시간을 받아오는 코드
    //     futureDate = dashboardData?.future[i]?.created_at;

    //     //미래(future) 시간 형식 바꾸는 코드
    //     let afterDay = dayjs(futureDate).format("HH:mm");

    //     //미래(future)에 예상되는 점수 값 
    //     afterTotalScore = dashboardData?.future[i]?.total_score;

    //     // dayjs 통해 형식을 수정한 값을 배열로 넣어주기 위한 문장(future)
    //     xArray2.push(afterDay)

    //     // 미래(future) 점수 값
    //     yArray2.push(afterTotalScore)

    // }

    //    // x축의 길이(before_data와 future)의 전체 갯수(길이)를 보여주기 위함
    // let xRange = (airquality_length+future_length)-2;

    //라벨 
    let y_labels = ['0','25','50','75', '100', '(%)']

    //y축 라벨 위치
    let y_labelsPosition = [0, 25, 50, 75, 100, 110]
    // let y_labelsPosition = [25, 50, 75, 100, 125, 135]
    let x_labelsPosition = []


    // 반응형에 따라 선과 폰트의 크기 변화하는 문장
    if (now_width >= 3000) {
        graph_font_size = 30;
        label_size = 7;
        data_line_size = 5;
        graph_point_font_size = 30;
        title_font_size = 23;
        graph_point_size = 32;
        pointLabel = 45;
        x_time_size = 23;
        x_labelsPosition = [0.035,0.35,0.67,0.98]
    } else if (now_width >= 2220 && now_width < 3000) {
        graph_font_size = 23;
        label_size = 5.5;
        data_line_size = 3.5;;
        graph_point_font_size = 15;
        title_font_size = 18;
        graph_point_size = 12;
        x_time_size = 14;
        pointLabel = 16;
        x_labelsPosition = [0.035,0.35,0.67,0.98]
    }
    else if (now_width >= 1900 && now_width < 2220) {
        graph_font_size = 13;
        label_size = 5.5;
        data_line_size = 3.5;;
        graph_point_font_size = 15;
        title_font_size = 18;
        graph_point_size = 12;
        x_time_size = 16;
        pointLabel = 27;
        x_labelsPosition = [0.03,0.338,0.66,0.98]
    } else if (now_width >= 1280 && now_width < 1900) {
        graph_font_size = 13;
        label_size = 5.5;
        data_line_size = 3.5;;
        graph_point_font_size = 13;
        title_font_size = 14;
        graph_point_size = 10;
        x_time_size = 12;
        pointLabel = 16;
        x_labelsPosition = [0.045,0.358,0.67,0.99]
}     

    
    // 현재(before_data)까지의 수치를 보여줌
    before_data = {
        x: from_now_range,
        y: percent_y_array,
        fill: 'tozeroy',
        fillopacity : 0.2 ,
        type:"scatter",
        mode:"lines",
        line: {
            width: data_line_size,
            shape: 'spline',
            color : '#00A7FF',
        },
    }



    // 미래(future)의 투명도가 적용되지 않은 값
    let future_Yvalue = yArray2.slice(0, yArray2.length-1)
    let future_Xvalue = xArray2.slice(0, xArray2.length-1)

    future_data = {
        x: future_Xvalue,
        y: future_Yvalue,
        type:"scatter",
        mode:"lines",
        line: {
            width: data_line_size,
            dash:'dot',
            shape:'spline',
            color: '#058ad1' 
        
        },
    }

    // 현재(airquality)의 수치를 marker로 표현함
    airquality_data_point = {
        x: [from_now_range[from_now_range.length-1]],
        y: [percent_y_array[from_now_range.length-1]],
        type: 'scatter',
        mode: 'markers',
        marker: {
            color: 'white',
            size: graph_point_size
        }

    }

    // 그래프에 들어갈 데이터들을 모아놓음
    // TotalScoreForm에 있는 Plot으로 전달하기 위함
    personData.push(before_data, airquality_data_point);
    
    //수치 라벨의 위치를 조정해주는 문장
    if(yArray[xArray.length-1] > 50) {
        pointLabel *= 1;
    } else if(yArray[xArray.length-1] <= 50){
        pointLabel *= -1;
    }
    
    // 포인트 라벨에 값이 없을 때 'new text' 안 뜨게 하는 문장
    let valueY;
    
    if( yArray[xArray.length-1] === undefined) {
        valueY = ' '
        
    } else {
        valueY = percent_y_array[3] +'%';
    }
    
   
    // layout 코드
    layout = { 
        font : {
            'family' : 'Poppins',
            // 'color' : 'rgb(74,135,168)',
            'size' : title_font_size,
        
        },
        paper_bgcolor:'rgb(23,23,23)',
        plot_bgcolor:'rgb(23,23,23)', 
        // 자동으로 그래프 사이즈가 변하지 않게 하기 위함
        autosize: true, 
        // showlegned는 그래프의 곡선의 성격을 보여줌 (해당 프로젝트에서는 사용하지 않음)
        showlegend: false,
        // 마우스를 그래프 곡선에 가리켜도 해당 정보가 보이지 않도록 함
        hovermode: false,
        //margin으로 plot의 크기 설정
        margin: {
            
            l: 47,
            r: 35,
            b: 50,
            t: 45,
            pad: 4
        },
        
        // 그래프의 x축 layout을 담당
        xaxis: {
            range: [0, x_range-0.85],
            // x축으로 볼 수 있는 범위
            showline: false,
            showgrid: false,
            showticklabels: true,
            linecolor: 'rgb(71,71,71)',
            linewidth: 2,
            //autotick: false,
            ticks: '',
            tickfont: {
                family: 'Poppins',
                size: x_time_size,
                color:'#171717'
            },
            fixedrange: true
        },
        // 그래프의 y축 layout을 담당
        yaxis: {
            range: [y_labelsPosition[0]-5, y_labelsPosition[5]],
            // y축으로 볼 수 있는 범위
            showgrid: false,
            gridcolor: 'rgb(71, 71, 71)',
            zeroline: false,
            showline: false,
            showticklabels: false,
            type: 'linear',
            fixedrange: true
            
        },
        
        shapes: [
            //x축 line 라벨
        {
            type: 'line',
            x0: from_now_range[0],
            y0: 0,
            x1: from_now_range[0],
            y1: 100,
            line: {
                color: 'rgb(71, 71, 71)',
                width: 1.5,
            dash: 'line'
            },
            opacity: 0.6,
            // shapes로 만든 y축 선을 data 아래에 배치되도록
            layer: 'below'  
        },
        {
        type: 'line',
        x0: from_now_range[1],
        y0: 0,
        x1: from_now_range[1],
        y1: 100,
        line: {
            color: 'rgb(71, 71, 71)',
            width: 1.5,
        dash: 'line'
        },
        opacity: 0.6,
        // shapes로 만든 y축 선을 data 아래에 배치되도록
        layer: 'below'  
    },
    {
        type: 'line',
        x0: from_now_range[2],
        y0: 0,
        x1: from_now_range[2],
        y1: 100,
        line: {
            color: 'rgb(71, 71, 71)',
            width: 1.5,
        dash: 'line'
        },
        opacity: 0.6,
        // shapes로 만든 y축 선을 data 아래에 배치되도록
        layer: 'below'  
    },
    {
        type: 'line',
    x0: from_now_range[3],
    y0: 0,
    x1: from_now_range[3],
    y1: 100,
    line: {
        color: 'rgb(71, 71, 71)',
        width: 1.5,
    dash: 'line'
    },
    opacity: 0.6,
    // shapes로 만든 y축 선을 data 아래에 배치되도록
    layer: 'below'  
    },
    // start_time의 현재 값을 나타내는 선
    {
        type: 'line',
    x0: from_now_range[3],
    y0: 0,
    x1: from_now_range[3],
    y1: percent_y_array[3],
    line: {
        color: '#00A7FF',
        width: 4.5,
    dash: 'dot'
    },
    // opacity: 0.6,
    
    // shapes로 만든 y축 선을 data 아래에 배치되도록
    layer: 'below' 
    },
    {
        type: 'line',
        x0: 0,
        y0: 0,
        x1: 47,
        y1: 0,
        line: {
        color: '#171717',
        width: 1.5,
        dash: 'line'
        },
        
        layer: 'below'   
    },
        
            
        ],
        annotations: [
            // {
            //     // 현재 시간의 수치를 보여주는 label
            //     type: 'scatter',
            //     xref: 'x',
            //     yref: 'y',
            //     arrowhead: 2,
            //     arrowsize : 25,
            //     arrowcolor:'#171717',
            //     ax: 0,
            //     ay: pointLabel -19,
            //     x: from_now_range[from_now_range.length-1],
            //     y: percent_y_array[from_now_range.length-1],
            //     text : valueY,
            //     // textposition: 'top right',
            //     font: {
            //         color: '#FFF500',
            //         size: graph_point_font_size,
            //     },
            //     showarrow: true,
            // },
            // {
            //     // 현재 시간의 수치를 보여주는 label
            //     type: 'scatter',
            //     xref: 'x',
            //     yref: 'y',
            //     arrowhead: 2,
            //     arrowsize : 25,
            //     arrowcolor:'#171717',
            //     ax: 0,
            //     ay: pointLabel,
            //     x: from_now_range[from_now_range.length-1],
            //     y: percent_y_array[from_now_range.length-1],
            //     text : '현재재실',
            //     textposition: 'top right',
            //     font: {
            //         color: 'white',
            //         size: graph_point_font_size,
            //     },
            //     showarrow: true,
            // },
        ],
    }




    //label을 반복문을 통해 그래프에 보여줌
    for( let i = 0 ; i < y_labels.length ; i++ ) {
        let y_result = {
            xref: 'paper',
            x: 0.006,
            y: y_labelsPosition[i],
            xanchor: 'right',
            yanchor: 'middle',
            text: `<b>${y_labels[i] + '  '}</b>`, 
            showarrow: false,
            font: {
                family: 'Poppins',
                size: graph_font_size,
                color: '#474747'
            }
        };
        layout.annotations.push(y_result);
    }
    
    for(let i = 0; i<x_only_hour.length; i++){
        let x_result = {
            xref: 'paper',
            x: x_labelsPosition[i],
            y: -3.5,
            xanchor: 'right',
            yanchor: 'middle',
            text: `<b>${x_only_hour[i] + '  '}</b>`, 
            showarrow: false,
            font: {
                family: 'Poppins',
                size: graph_font_size,
                color: '#474747'
            }
        };
    
    
    layout.annotations.push(x_result);
    }


    return(
        <PersonGraphForm
            personData={personData}
            layout={layout}
            valueY={valueY}
            percent_y_array ={percent_y_array}
        />
    )
}

export default PersonGraphDetail;