import './App.css';
import { Route, Switch, BrowserRouter } from 'react-router-dom';

import { DashboardSlick } from '../components';
import DashboardSlick02 from '../components/DashboardSlick02';
import DashboardSlick03 from '../components/DashboardSlick03';

import MyondoMainForm from '../components/MyondoMainForm';

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path='/' exact component={DashboardSlick03} />
        <Route path='/myondo/' exact component={MyondoMainForm} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
