import React from "react";
import TemperatureForm from "../../components/lineChartGraph/TemperatureForm";
import dayjs from "dayjs";

const TemperatureDetail = ({dashboardData}) => {

    //현재까지의 created_at, total_score의 값과 이후에 예상되는 created_at, total_score의 값 
    let beforeDate;
    let futureDate;
    let beforeTemperatureScore;
    let afterTemperatureScore;
    
    // x축과 y축에 값을 넣어줄 배열
    let xArray = []
    let yArray = []    
    let xArray2 = []
    let yArray2 = [] 
    
    //airquality의 data(값)을 담아줌
    let airquality_data

    // future의 data(값)을 담아줌
    let future_data

    // future의 마지막 값
    let future_data_last

    // airquality의 마지막 값, 그래프에서 보여질 값을 담아줌
    let airquality_data_point;

    
    //data(x축의 값들과 y축의 값들을 포함한)
    let tempertureData = [];

    // airquality와 future의 길이(갯수)
    let airquality_lenth = dashboardData?.airquality.length;
    let future_length = dashboardData?.future.length;

    // x축의 길이(airquality와 future)의 전체 갯수(길이)를 보여주기 위함
    let xRange = (airquality_lenth+future_length)-2;
        
    // 현재 브라우저의 크기
    let now_width = window.innerWidth;

    // 그래프 폰트 size
    let graph_font_size;
    let graph_point_font_size;
    let graph_point_size;
    let label_size;
    let data_line_size;
    let title_font_size;
    let x_time_size;

    //그래프 현재 값 text 위치
    let pointLabel;

    //그래프 x축 값에 따른 색상변화
    let merged_array
    let divide_merged_array =[]

    // 최근 값에 따른 airquality 색상 변경 변수
    let air_line_color

    for(let i = 0; i < airquality_lenth; i++) {

        //서버에서 날짜 받아오는 코드
        beforeDate = dashboardData?.airquality[i]?.created_at;       
        
        // 서버에서 받아온 날짜 형식 바꾸는 코드
        let day = dayjs(beforeDate).format("HH:mm");
        
        // 현재까지의 값과 앞으로 예상되는 값
        beforeTemperatureScore = dashboardData?.airquality[i]?.temperature_percent;
        
        xArray.push(day)
        yArray.push(beforeTemperatureScore)
                
    }

    for(let i = 0; i < future_length; i++) {

         //서버에서 날짜 받아오는 코드
        futureDate = dashboardData?.future[i]?.created_at;

          // 서버에서 받아온 날짜 형식 바꾸는 코드
        let afterDay = dayjs(futureDate).format("HH:mm");

        // 현재까지의 값과 앞으로 예상되는 값
        afterTemperatureScore = dashboardData?.future[i]?.temperature_percent;
        
        xArray2.push(afterDay)
        yArray2.push(afterTemperatureScore)

    }

    // airquality과 future x값 합치기
    merged_array = xArray.concat(xArray2);

    // 합친(airquality와 future) 배열 중복값 허용
    let total_merged_array = merged_array.filter((item, pos) => merged_array.indexOf(item) === pos);
        

    // 반응형에 따라 선과 폰트의 크기 변화하는 문장
    if (now_width >= 3000) {
        graph_font_size = 21;
        label_size = 7;
        data_line_size = 5;
        graph_point_font_size = 30;
        title_font_size = 23;
        graph_point_size = 32;
        x_time_size = 23;
        pointLabel = 35;
        // console.log('1')
    } else if (now_width >= 2220 && now_width < 3000) {
        graph_font_size = 13;
        label_size = 5.5;
        data_line_size = 3.5;;
        graph_point_font_size = 15;
        title_font_size = 18;
        graph_point_size = 12;
        x_time_size = 14;
        pointLabel = 16;
        // console.log('2')
    }
    else if (now_width >= 1900 && now_width < 2220) {
        graph_font_size = 13;
        label_size = 5.5;
        data_line_size = 3.5;;
        graph_point_font_size = 15;
        title_font_size = 13;
        graph_point_size = 12;
        x_time_size =10.5;
        pointLabel = 16;
        // console.log('3')
    } else if (now_width >= 1280 && now_width < 1900) {
        graph_font_size = 13;
        label_size = 5.5;
        data_line_size = 3.5;;
        graph_point_font_size = 15;
        title_font_size = 13;
        graph_point_size = 10;
        x_time_size = 6.8;
        pointLabel = 16;
        // console.log('4')
    }

        // 그래프 layout 계절의 range(범위) 설정
        let tem1 = 0;
        let tem2 = 20;
        let tem3 = 80;
        let tem4 = 100;
    
        // let toDay = dayjs(new Date()).format("MMDD");
        // const toDayCheck = Number(toDay)
    
        // if((1107 <= toDayCheck && toDayCheck <= 1231) || (101 <= toDayCheck && toDayCheck <= 203 ) ){
        //     tem1 = 4;
        //     tem2 = 10;
        //     tem3 = 25;
        //     tem4 = 31;
        //     // console.log('온도 겨울')
        // } else if(( 204 <= toDayCheck && toDayCheck <= 505) || (807 <= toDayCheck && toDayCheck <= 1106)){
        //     tem1 = 4;
        //     tem2 = 10;
        //     tem3 = 28;
        //     tem4 = 34
        //     // console.log('온도 봄가을')
        // } else if (506 <= toDayCheck && toDayCheck <= 806){
        //     tem1 = 9;
        //     tem2 = 15;
        //     tem3 = 30;
        //     tem4 = 36;
        //     // console.log('온도 여름')
        // }
    
        //y축 라벨 color
        let labelsColor =['#58a7d1','#1cb43e', '#d16161']
    
        //y축 라벨 이름
        let labels = ['낮음','적정','높음']
    
        let labelPosition_low = ((tem2-tem1) / 2)+ tem1; 
        let labelPosition_middle = ((tem3-tem2) / 2) + tem2;
        let labelPosition_high = ((tem4-tem3)/2) + tem3;
        
        //y축 라벨 위치
        let labelsPosition = [labelPosition_low, labelPosition_middle, labelPosition_high]
    
    

    // 현재시간의 수치를 marker로 표현함
    airquality_data_point = {

        x: [xArray[xArray.length-1]],
        y: [yArray[xArray.length-1]],
        type: 'scatter',
        mode: 'markers',
        marker: {
            color: 'white',
            size: graph_point_size
        }

    }

    // airquality 현재값 기준으로 line color 설정
    if([yArray[xArray.length-1]] <= tem2) {
        air_line_color = '#58a7d1'
    } else if([yArray[xArray.length-1]] > tem2 && [yArray[xArray.length-1]] <= tem3){
        air_line_color = '#1cb43e'
    } else if([yArray[xArray.length-1]] > tem3){
        air_line_color = '#d16161'
    }

    // 현재시간까지의 수치를 보여줌
    airquality_data = {
        x: xArray,
        y: yArray,
        type:"scatter",
        mode:"lines",
        line: {
            width: data_line_size,
            shape:'spline',
            color: air_line_color
        
        }

    }

    // 미래(future)의 투명도가 적용되지 않은 값
    let future_Yvalue = yArray2.slice(0, yArray2.length-1)
    let future_Xvalue = xArray2.slice(0, xArray2.length-1)

    future_data = {
        x: future_Xvalue,
        y: future_Yvalue,
        type:"scatter",
        mode:"lines",
        line: {
            width: data_line_size,
            dash:'dot',
            shape:'spline',
            color: '#0973ac' 
        
        },
    }

    //미래(future)의 투명도가 적용된 값
    let last_future_Yvalue = yArray2.slice(yArray2.length-2, yArray2.length-0)
    let last_future_Xvalue = xArray2.slice(xArray2.length-2, xArray2.length-0)

    future_data_last = {
        x: last_future_Xvalue,
        y: last_future_Yvalue,
        type:"scatter",
        mode:"lines",
        opacity: 0.5,
        line: {
            width: data_line_size,
            dash:'dot',
            shape:'spline',
            color: '#0973ac'
        },
            
    }

    // 그래프에 들어갈 데이터들을 모아놓음
    tempertureData.push(airquality_data, future_data, airquality_data_point,  future_data_last);

    //그래프 x축 값에 따라 색상 변경 
    for(let i = 0; i<=xRange; i++) {

        if(i % 2 === 0) {
            divide_merged_array.push(`<span style="color:rgb(226, 226, 226)">${total_merged_array[i]}</span>`)
        } else if(i % 2 === 1) {
            divide_merged_array.push(`<span style="color:rgb(23, 23, 23)">${total_merged_array[i]}</span>`)
        }
    }

    // 그래프의 layout 정보를 담는 변수
    let layout

    //수치 라벨의 위치를 조정해주는 문장
    if(yArray[xArray.length-1] > 50) {
        pointLabel *= 1;
    } else if(yArray[xArray.length-1] <= 50){
        pointLabel *= -1
    }        

    // 포인트 라벨에 값이 없을 때 new text 안 뜨게 하는 문장
    let valueY;

    if( yArray[xArray.length-1] === undefined) {
        valueY = ' '
        
    } else {
        valueY = dashboardData?.airquality[5]?.temperature;
    }

    // layout 코드
    layout = { 
    title: '<b>온도(°C)</b>',
    font : {
        'color' : '#acacac',
        'size' : title_font_size,
        
    },

    paper_bgcolor:'rgb(23,23,23)',
    plot_bgcolor:'rgb(23,23,23)', 
    autosize: true, 
    showlegend: false,
    // width: 331,
    // height: 188,
    hovermode: false,
    //margin으로 plot의 크기 설정
    margin: {
        l: 47,
        r: 25,
        b: 40,
        t: 45,
        pad: 4
    },

    // 그래프의 x축 layout을 담당
    xaxis: {
        range: [0, xRange],
        // x축으로 볼 수 있는 범위
        showline: false,
        showgrid: false,
        showticklabels: true,
        linecolor: 'rgb(71,71,71)',
        linewidth: 2,
        //autotick: false,
        ticks: '',
        tickfont: {
            family: 'NanumGothic',
            size: x_time_size,
        },
        fixedrange: true,
        tickvals : total_merged_array,
        ticktext: divide_merged_array,
    },

    // 그래프의 y축 layout을 담당
    yaxis: {
        range: [tem1, tem4],
        // y축으로 볼 수 있는 범위
        showgrid: false,
        gridcolor: 'rgb(71, 71, 71)',
        zeroline: false,
        showline: false,
        showticklabels: false,
        type: 'linear',
        fixedrange: true
        
    },

    shapes: [
        // y축 line 라벨
        {
            type: 'line',
            x0: 0,
            y0: tem1,
            x1: 47,
            y1: tem1,
            line: {
            color: 'rgb(71, 71, 71)',
            width: 1.5,
            dash: 'line'
            },
            opacity: 0.6,
            layer: 'below'
        },
        {
            type: 'line',
            x0: 0,
            y0: tem2,
            x1: 47,
            y1: tem2,
            line: {
            color: 'rgb(71, 71, 71)',
            width: 1.5,
            dash: 'line'
            },
            opacity: 0.6,
            layer: 'below'  
        },
        {
            type: 'line',
            x0: 0,
            y0: tem3,
            x1: 47,
            y1: tem3,
            line: {
            color: 'rgb(71, 71, 71)',
            width: 1.5,
            dash: 'line'
            },
            opacity: 0.6,
            layer: 'below'  
        },
        {
            type: 'line',
            x0: 0,
            y0: tem4,
            x1: 47,
            y1: tem4,
            line: {
            color: 'rgb(71, 71, 71)',
            width: 1.5,
            dash: 'line'
            },
            opacity: 0.6,
            layer: 'below'  
        },
           //label color line
        {
            type: 'line',
            x0: 0,
            y0: tem1,
            x1: 0,
            y1: tem2,
            line: {
            color: '#58a7d1',
            width: label_size,
            dash: 'line'
            }  
        },
        {
            type: 'line',
            x0: 0,
            y0: tem2,
            x1: 0,
            y1: tem3,
            line: {
            color: '#1cb43e',
            width: label_size,
            dash: 'line'
            }  
        },
        {
            type: 'line',
            x0: 0,
            y0: tem3,
            x1: 0,
            y1: tem4,
            line: {
            color: '#d16161',
            width: label_size,
            dash: 'line'
            }  
        },
    ],
    annotations: [
        {
            // 현재 시간의 수치를 보여주는 label
            type: 'scatter',
            xref: 'x',
            yref: 'y',
            arrowhead: 0,
            arrowsize : 0,
            arrowcolor:'white',
            ax: 0,
            ay: pointLabel,
            x: xArray[xArray.length-1],
            y: yArray[xArray.length-1],
            text : valueY,
            textposition: 'top right',
            font: {
                color: 'white',
                size: graph_point_font_size,
            },
            showarrow: true,
        },
    ]
    }


     //label을 반복문을 통해 그래프에 보여줌
    for( let i = 0 ; i < labels.length ; i++ ) {

    
        let result = {
            xref: 'paper',
            x: 0.008,
            y: labelsPosition[i],
            xanchor: 'right',
            yanchor: 'middle',
            text: `<b>${labels[i] + '  '}</b>`,  
            showarrow: false,
            font: {
                family: 'NanumGothic',
                size: graph_font_size,
                color: labelsColor[i]
            }
        };
        layout.annotations.push(result);
        }


    return(
        <TemperatureForm
            tempertureData={tempertureData}
            layout={layout}
        />
    )
}

export default TemperatureDetail;