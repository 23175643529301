import React from 'react';
import { Container, Grid, Text, Image } from '../elements';
import GraphForm from './GraphForm';

import aski from '../assets/Icon/aski.png';
import icon1 from '../assets/Icon/icon1.png';
import icon2 from '../assets/Icon/icon2.png';
import icon3 from '../assets/Icon/icon3.png';
import icon4 from '../assets/Icon/icon4.png';
import icon_location from '../assets/Icon/icon_location.png';

const Dashboard = (props) => {
  const dashboardData = props;

  const weatherData = dashboardData?.weather;

  let yesterday_temp = Math.abs(weatherData?.before_yesterday);
    
  //yesterday_temp가 Nan으로 보이므로 값을 0으로 처리
  if(isNaN(yesterday_temp)) {
    yesterday_temp = 0;
  }

  let totalScore =
    dashboardData?.airquality[dashboardData?.airquality.length - 1]
      ?.total_score;
  let pm25 =
    weatherData?.pm25;
  let pm10 =
    weatherData?.pm10;

  return (
    <React.Fragment>
      <Container width='100%' height='100%' bgColor='#262626' mainFlex>
        <Grid width='calc( 100% - 41.6%)' height='100%' center>
          <Grid width='93%' height='4%' margin='4% 4.11vw 0 2vw' inlineCenter>
            <Text size='0.94vw' color='#929292' bold='700' margin='0 16px 0 0'>
              아스크스토리
            </Text>
            <hr width='20%' size='0' color='#000000' bold='1px' />
            <Text
              size='1.88vw'
              color='#FFFFFF'
              bold='700'
              margin='0 31px 0 31px'
            >
              통합환경지수
            </Text>
            <hr width='20%' size='0' color='#000000' bold='1px' />
            <Text size='0.94vw' color='#929292' bold='700' margin='0 0 0 14px'>
              {weatherData?.installed_place}
            </Text>
          </Grid>
          <Grid
            width='100%'
            height='17%'
            center
            position='relative'
            margin='7.8vw auto 10.99vw auto'
          >
            <Image
              shape='circle'
              margin='0 auto 0 auto'
              left='24%'
              size='56.5vh'
              width='56.5vh'
              height='600px'
              src='https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2F8yJBD%2FbtrvLz6cZsy%2FgHrVLiY6uJL2KvNDZSyZs0%2Fimg.webp'
            ></Image>
            <Grid
              shape='circle'
              width='12vw'
              position='relative'
            >
              {totalScore >= 20 && totalScore < 40 ? (
                <Text size='7vw' color='#FB2F2E' bold='400'>
                  {totalScore}
                </Text>
              ) : totalScore >= 40 && totalScore < 60 ? (
                <Text size='7vw' color='#F4A522' bold='400'>
                  {totalScore}
                </Text>
              ) : totalScore >= 60 && totalScore < 80 ? (
                <Text size='7vw' color='#1DDC48' bold='400'>
                  {totalScore}
                </Text>
              ) : totalScore >= 80 && totalScore <= 100 ? (
                <Text size='7vw' color='#00A7FF' bold='400'>
                  {totalScore}
                </Text>
              ) : null}
              {totalScore >= 20 && totalScore < 40 ? (
                <Text size='1.25vw' color='#FB2F2E' bold='300'>
                  위험
                </Text>
              ) : totalScore >= 40 && totalScore < 60 ? (
                <Text size='1.25vw' color='#F4A522' bold='300'>
                  나쁨
                </Text>
              ) : totalScore >= 60 && totalScore < 80 ? (
                <Text size='1.25vw' color='#1DDC48' bold='300'>
                  보통
                </Text>
              ) : totalScore >= 80 && totalScore <= 100 ? (
                <Text size='1.25vw' color='#00A7FF' bold='300'>
                  좋음
                </Text>
              ) : null}
            </Grid>
          </Grid>
          <Grid>
            <Image
              position='absolute'
              left='7vh'
              top='27vh'
              width='66px'
              height='66px'
              size='66px'
              src={aski}
            ></Image>
          </Grid>
        </Grid>
        <Grid width='calc(100% - 60.4%)' height='93%'>
          <Grid
            width='100%'
            height='6vw'
            margin='3% 8% 2% 0'
            borderRadius='20px'
            bgColor='#2F4EA7'
            position='relative'
            mainFlex
          >
            <Grid width='30%' margin='1.4vw 0 1.5vw 1.5vw'>
              <Text
                size='1.25vw'
                bold='700'
                color='#FFFFFF'
                margin='0 0 1.19vw 0'
              >
                우리 지역 정보
              </Text>
              <Grid startCenter>
                <Image
                  width='12px'
                  height='12px'
                  size='75%'
                  src={icon_location}
                ></Image>
                <Text
                  size='0.73vw'
                  bold='400'
                  color='#FFFFFF'
                  padding='0 0 0 8px'
                >
                  {weatherData?.place}
                </Text>
              </Grid>
            </Grid>
            <Grid width='40%' mainFlex margin='auto 3% auto 1.39vw'>
              <Text size='3.44vw' bold='400' color='#FFFFFF' padding='0 3% 0 0'>
                {weatherData?.temp}°
              </Text>
              <Grid width='9.8vw' centerFlex>
                <Text size='0.63vw' bold='400'>
                  {weatherData?.description}
                </Text>
                {weatherData?.before_yesterday > 0 ? (
                  <Text size='0.63vw' bold='400'>
                    어제보다 {yesterday_temp}도 높아요
                  </Text>
                ) : (
                  <Text size='0.63vw' bold='400'>
                    어제보다 {yesterday_temp}도 낮아요
                  </Text>
                )}
              </Grid>
            </Grid>
            <Grid width='30%' margin='0 -1.41vw 0 0'>
              <Grid width='8.79vw' margin='auto 48px auto 0'>
              <Grid height='18px' padding='0 0 0.63vw 0' startCenter>
                  {pm10 >= 1 && pm10 <= 50 ? (
                    <Image
                      width='18px'
                      height='18px'
                      position='relative'
                      src={icon1}
                    ></Image>
                  ) : pm10 > 50 && pm10 <= 70 ? (
                    <Image
                      width='18px'
                      height='18px'
                      position='relative'
                      src={icon2}
                    ></Image>
                  ) : pm10 > 70 && pm10 <= 150 ? (
                    <Image
                      width='18px'
                      height='18px'
                      position='relative'
                      src={icon3}
                    ></Image>
                  ) : pm10 > 150 && pm10 <= 900 ? (
                    <Image
                      width='18px'
                      height='18px'
                      position='relative'
                      src={icon4}
                    ></Image>
                  ) : null}
                  <Text size='0.63vw' bold='400' padding='0 0 0 0.68vw'>
                    미세먼지 
                  </Text>
                  <Text size='0.63vw' bold='400' padding='0 0 0 0.25vw'>{pm10}</Text>
                </Grid>
                <Grid height='18px' startCenter>
                  {pm25 >= 0 && pm25 <= 15 ? (
                    <Image
                      width='18px'
                      height='18px'
                      position='relative'
                      src={icon1}
                    ></Image>
                  ) : pm25 > 15 && pm25 <= 35 ? (
                    <Image
                      width='18px'
                      height='18px'
                      position='relative'
                      src={icon2}
                    ></Image>
                  ) : pm25 > 35 && pm25 <= 75 ? (
                    <Image
                      width='18px'
                      height='18px'
                      position='relative'
                      src={icon3}
                    ></Image>
                  ) : pm25 > 75 && pm25 <= 500 ? (
                    <Image
                      width='18px'
                      height='18px'
                      position='relative'
                      src={icon4}
                    ></Image>
                  ) : null}
                  <Text size='0.63vw' bold='400' padding='0 0 0 0.68vw'>
                    초미세먼지 
                  </Text>
                  <Text size='0.63vw' bold='400' padding='0 0 0 0.25vw'>{pm25}</Text>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <GraphForm dashboardData={dashboardData} />
        </Grid>
      </Container>
    </React.Fragment>
  );
};

export default Dashboard;
