import React from 'react';
import styled from 'styled-components';
import customMedia from '../components/customMedia';

const Image = (props) => {
    const {
        shape,
        src,
        size,
        _onClick,
        cursor,
        width,
        height,
        borderRadius,
        margin,
        padding,
        title,
        top,
        left,
        position,
        display,
    } = props;

    const styles = {
        src: src,
        size: size,
        width: width,
        height: height,
        _onClick,
        borderRadius: borderRadius,
        margin: margin,
        cursor,
        padding: padding,
        title,
        top: top,
        left: left,
        position: position,
        display: display,
    }

    if (shape === 'circle') {
        return <ImageCircle {...styles}></ImageCircle>;
    }

    return (
        <React.Fragment>
          <AspectOutter>
            <AspectInner {...styles} />
          </AspectOutter>
        </React.Fragment>
      );
};

Image.defaultProps = {
    shape: null,
    src: '',
    size: 36,
    width: '',
    height: '',
    margin: '',
    borderRadius: '',
    cursor: '',
    _onClick: () => {},
    padding: '',
    title: '',
    left: '',
    position: '',
};

const AspectOutter = styled.div`
    width: auto;

    ${customMedia.lessThan('uhd')`
    width : ${(props) => props.width};
`}
`;

const AspectInner = styled.div`
    width: ${(props) => props.width};
    height: ${(props) => props.height};
    cursor: ${(props) => props.cursor};
    position: ${(props) => props.position};
    overflow: hidden;
    top: ${(props) => props.top};
    left: ${(props) => props.left};
    background-image: url("${(props) => props.src}");
    background-size: cover;
    background-position: center center;

    ${customMedia.lessThan('uhd')`
    width : ${(props) => props.width};
    top: ${(props) => props.top};
    left: ${(props) => props.left};
    `
    }

    ${customMedia.lessThan('qhd')`
      width : ${(props) => props.width};
      top: ${(props) => props.top};
      left: ${(props) => props.left};
    `
    }

    ${customMedia.lessThan('hd')`
      width : ${(props) => props.width};
      top: ${(props) => props.top};
      left: ${(props) => props.left};
    `
    }

    ${customMedia.lessThan('wxga')`
      width : ${(props) => props.width};
      top: ${(props) => props.top};
      left: ${(props) => props.left};
      `
    }

    ${customMedia.lessThan('tablet')`
      width : ${(props) => props.width};
      top: ${(props) => props.top};
      left: ${(props) => props.left};
    `}

${customMedia.lessThan('tabletMini')`
      width : ${(props) => props.width};
      top: ${(props) => props.top};
      left: ${(props) => props.left};
    `}

`;

const ImageCircle = styled.div`
  --size: ${(props) => props.size};
//--size: ${(props) => props.size}px;
  position: absolute;
  display: ${(props) => props.display};
  width: var(--size);
  height: var(--size);
  position: ${(props) => props.position};
  border-radius: var(--size);
  top: ${(props) => props.top};
  left: ${(props) => props.left};
  background-image: url('${(props) => props.src}');
  background-size: cover; 
  margin: ${(props) => props.margin};
  cursor: ${(props) => props.cursor};
  
  ${customMedia.lessThan('uhd')`
    width : ${(props) => props.width};
    left: ${(props) => props.left};
    --size: ${(props) => props.size};
    
    `}
    
  ${customMedia.lessThan('qhd')`
    width : ${(props) => props.width};
    left: ${(props) => props.left};
    --size: ${(props) => props.size};
    
    `}

  ${customMedia.lessThan('hd')`
  width : ${(props) => props.width};
  --size: ${(props) => props.size};
  --size: ${(props) => props.size};
  `}

  ${customMedia.lessThan('wxga')`
  width : ${(props) => props.width};
  left: ${(props) => props.left};
  --size: ${(props) => props.size};
  left : 21%

  `}


  ${customMedia.lessThan('tabletGalaxy')`
  width : ${(props) => props.width};
  --size: ${(props) => props.size};
  left: 22.9%;
  `}

  ${customMedia.lessThan('tablet')`
  width : ${(props) => props.width};
  left: 19.7%
  `}

  ${customMedia.lessThan('tabletMini')`
  width : ${(props) => props.width};
  left: 16%
  `}


`;

export default Image;