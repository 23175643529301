import { createAction, handleActions } from 'redux-actions';
import { produce } from 'immer';
import { apis } from '../../shared/axios';

// Actions
const GET_DASHBOARD = 'GET_DASHBOARD';
const GET_DASHBOARD2 = 'GET_DASHBOARD1';
const GET_DASHBOARD3 = 'GET_DASHBOARD2';
const GET_MYONDO = 'GET_MYONDO';

const initialState = {
  data: [],
};

// Action Creators
const getDashboard = createAction(GET_DASHBOARD, (dashboardData) => ({
  dashboardData,
}));
const getDashboard02 = createAction(GET_DASHBOARD2, (dashboardData02) => ({
  dashboardData02,
}));
const getDashboard03 = createAction(GET_DASHBOARD3, (dashboardData03) => ({
  dashboardData03,
}));
const getMyondo = createAction(GET_MYONDO, (myondo) => ({
  myondo,
}));


// Thunk functions
const getDashboardMW = (dashboardData) => {
  return (dispatch, getState, { history }) => {
    apis
      .getDashboardAX(dashboardData)
      .then((res) => {
        const get_dashboard = res.data;
        dispatch(getDashboard(get_dashboard));
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
};

const getDashboard02MW = (dashboardData02) => {
  return (dispatch) => {
    apis
      .getDashboard02AX(dashboardData02)
      .then((res) => {
        const get_dashboard02 = res.data;
        dispatch(getDashboard02(get_dashboard02));
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
};

const getDashboard03MW = (dashboardData03) => {
  return (dispatch) => {
    apis
      .getDashboard03AX(dashboardData03)
      .then((res) => {
        const get_dashboard03 = res.data;
        dispatch(getDashboard03(get_dashboard03));
      })
      .catch((err) => {
        console.log(err.message);
      });
    };
  };
  
  const getMyondoMW = (myondo) => {
    return (dispatch) => {
      apis
      .getMyondoAX(myondo)
      .then((res) => {
        const get_myondo = res.data;
        dispatch(getMyondo(get_myondo));
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
};

export default handleActions(
  {
    [GET_DASHBOARD]: (state, action) =>
      produce(state, (draft) => {
        draft.dashboardData = action.payload.dashboardData;
      }),

    [GET_DASHBOARD2]: (state, action) =>
      produce(state, (draft) => {
        draft.dashboardData02 = action.payload.dashboardData02;
      }),

    [GET_DASHBOARD3]: (state, action) =>
      produce(state, (draft) => {
        draft.dashboardData03 = action.payload.dashboardData03;
      }),
    [GET_MYONDO]: (state, action) =>
      produce(state, (draft) => {
        draft.myondo = action.payload.myondo;
    }),
  },
  initialState,
);

const dataCreators = {
  getDashboardMW,
  getDashboard02MW,
  getDashboard03MW,
  getMyondoMW
};

export { dataCreators };
