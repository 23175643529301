import React from "react";
import Plot from 'react-plotly.js'
import { Grid, Image, Text } from "../elements";
import speechBuble from '../assets/Icon/speech_bubble.png'

const EnergyBarForm = ({personData, layout, valueY, afterTotalScore}) => {
    
    let now_width = window.innerWidth;

        // 말풍선 postion 

        let image_top;
        let image_left;
        let text_percent_top;
        let text_percent_left;

        // 말풍선 및 text 위치 조절
        let position_img_top;
        let position_text_percent_top;

    
        let point_pad_position 
        let point_tv_position

        if(afterTotalScore >= 0 && afterTotalScore <=5) {
            point_pad_position = 0.2
            point_tv_position = 25
            afterTotalScore = 1
        } else if(afterTotalScore >= 6 && afterTotalScore <= 10 ) {
            point_pad_position = 1
            point_tv_position = 4.6
        } else if(afterTotalScore >= 11 && afterTotalScore <= 20 ) {
            point_pad_position = 2
            point_tv_position = 4.1
        }  else if(afterTotalScore >= 21 && afterTotalScore <= 40 ) {
            point_pad_position = 2.2
            point_tv_position = 3.45
        }
        else if(afterTotalScore >= 41 && afterTotalScore <= 70 ) {
            point_pad_position = 2.3
            point_tv_position = 3.1
        } else if(afterTotalScore >= 71 && afterTotalScore <= 100 ){
            point_pad_position = 2.35
            point_tv_position = 3.09
        }
    
    
        let point_position_pad = (afterTotalScore* 0.1) * point_pad_position
        let point_position_tv = (afterTotalScore* 0.1) * point_tv_position
    
    
        if (now_width >= 3000) {
            position_img_top = 28.906
            position_text_percent_top = 30.150
            image_top = (position_img_top - point_position_tv)
            image_left = 7.197
            text_percent_top= (position_text_percent_top - point_position_tv)
            text_percent_left = 8.655
            
        } else if (now_width >= 2220 && now_width < 3000) {
            position_img_top = 28.906
            position_text_percent_top = 30.150
            image_top = (position_img_top - point_position_tv)
            image_left = 7.197
            text_percent_top= (position_text_percent_top - point_position_tv)
            text_percent_left = 8.655

        
        } else if (now_width >= 1900 && now_width < 2220) {
            position_img_top = 28.906
            position_text_percent_top = 30.150
            image_top = (position_img_top - point_position_tv)
            image_left = 7.197
            text_percent_top= (position_text_percent_top - point_position_tv)
            text_percent_left = 8.655

    
        } else if (now_width >= 1280 && now_width < 1900) {
            position_img_top = 25.006
            position_text_percent_top = 26.250
            image_top = (position_img_top - point_position_pad)
            image_left = 7.297
            text_percent_top= (position_text_percent_top - point_position_pad)
            text_percent_left = 8.725

        }     
    


    return(
            <Grid margin="0.8vw 0 0 0" bgColor ='rgb(23,23,23)' borderRadius='1vw'   >
                <Plot
                    data={personData}
                    layout={layout}
                    config={{
                        responsive : true,
                        displayModeBar : false
                    }}
                    
                    style = {{width: "90%", height: '34.06vw', useResizeHandler: true, margin:'0 auto' }}
                />
                 <Image 
                    position='absolute'
                    src={speechBuble}
                    top = {image_top + 'vw' }
                    left= {image_left + 'vw' }
                    width='6.868vw'
                    height='3.854vw'
                />
                <Text
                fontFamily = 'Poppins'
                position="absolute"
                top={text_percent_top +'vw'}
                left={text_percent_left +'vw'}
                size="0.79vw"
                width="3.9vw"
                color="#FFF500"
                >
                    {valueY}
                </Text>
            </Grid>
    )
}

export default EnergyBarForm;