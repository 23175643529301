import React from 'react';
import styled from 'styled-components';
import customMedia from '../components/customMedia';

const Grid = (props) => {
  const {
      margin,
      padding,
      cursor,
      width,
      height,
      children,
      bgColor,
      position,
      maxWidth,
      center, 
      borderRadius,
      inlineCenter,
      mainFlex,
      centerFlex,
      startCenter,
      columnCenter,
      flexCenter,
      shape,
      float } = props;
  const styles = { margin, height, padding, cursor, width, maxWidth, bgColor, position, center, borderRadius, inlineCenter, mainFlex, centerFlex, startCenter, columnCenter, flexCenter, shape, float };

  if (shape === 'circle') {
    return <ImageGrid {...styles}>{children}</ImageGrid>;
}


  return (
    <React.Fragment>
      <ElGrid {...styles}>{children}</ElGrid>
    </React.Fragment>
  );
};

Grid.defaultProps = {
  children: null,
  width: '',
  height: '',
  margin: '',
  padding: '',
  cursor: '',
  bgColor: '',
  borderRadius: '',
  center: '',
  position: '',
};

const ElGrid = styled.div`
  max-width: ${(props) => props.maxWidth};
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  margin: ${(props) => props.margin};
  padding: ${(props) => props.padding};
  cursor: ${(props) => props.cursor};
  background-color: ${(props) => props.bgColor};
  position: relative;
  float:${(props) => props.float};
  ${(props) =>
    props.borderRadius ? `border-radius : ${props.borderRadius};` : ''};
  ${(props) => (props.center ? `text-align: center;` : '')}
  ${(props) => (props.inlineCenter ? `display: inline-flex; align-items: center;` : '')};
  ${(props) => (props.flexCenter ? `display: flex; justify-content: center;` : '')};
  ${(props) => (props.mainFlex ? `display: flex; align-items: center;` : '')};
  ${(props) => (props.centerFlex ? `display: column; align-items: flex-start` : '')};
  ${(props) => (props.startCenter ? `display: flex; justify-content: flex-start; align-items: center;` : '')};
  ${(props) => (props.columnCenter ? `display: flex; flex-direction:column; align-content: center; align-items: center;` : '')};
  
    ${customMedia.lessThan('uhd')`
      width : ${(props) => props.width};
      height: ${(props) => props.height};
      margin: ${(props) => props.margin};
    `}

    ${customMedia.lessThan('qhd')`
      width : ${(props) => props.width};
      height: ${(props) => props.height};
      margin: ${(props) => props.margin};
    `}

    ${customMedia.lessThan('hd')`
      width : ${(props) => props.width};
      height: ${(props) => props.height};
      margin: ${(props) => props.margin};
    `}

    ${customMedia.lessThan('wxga')`
      width : ${(props) => props.width};
      height: ${(props) => props.height};
      margin: ${(props) => props.margin};
  `}
  

    ${customMedia.lessThan('tabletGalaxy')`
    width : ${(props) => props.width};
    height: ${(props) => props.height};
    margin: ${(props) => props.margin};
    `}

    ${customMedia.lessThan('tablet')`
      width : ${(props) => props.width};
      height: ${(props) => props.height};
      margin: ${(props) => props.margin};
    `}

    ${customMedia.lessThan('tabletMini')`
      width : ${(props) => props.width};
      height: ${(props) => props.height};
      margin: ${(props) => props.margin};
  `}


`;

const ImageGrid = styled.div`
max-width: ${(props) => props.maxWidth};
width: ${(props) => props.width};
height: ${(props) => props.height};
margin: ${(props) => props.margin};
padding: ${(props) => props.padding};

position: relative;


${customMedia.lessThan('uhd')`
      width : ${(props) => props.width};
      height: ${(props) => props.height};
      margin: ${(props) => props.margin};
      padding: 19.6vh 50vh 0 47vh;
      `}
      
      ${customMedia.lessThan('qhd')`
      width : ${(props) => props.width};
      height: ${(props) => props.height};
      margin: ${(props) => props.margin};
      `}

    ${customMedia.lessThan('hd')`
      width : ${(props) => props.width};
      height: ${(props) => props.height};
      padding: 19.6vh 41vh 0 42vh;
    `}

    ${customMedia.lessThan('wxga')`
      width : ${(props) => props.width};
      height: ${(props) => props.height};
      margin: ${(props) => props.margin};
  `}

    
  ${customMedia.lessThan('tabletGalaxy')`
  width : ${(props) => props.width};
  height: ${(props) => props.height};
  padding: 20vh 41vh 0 40vh;
  `}
  

    ${customMedia.lessThan('tablet')`
      width : ${(props) => props.width};
      height: ${(props) => props.height};
      margin: ${(props) => props.margin};
    `}

    ${customMedia.lessThan('tabletMini')`
      width : ${(props) => props.width};
      height: ${(props) => props.height};
      margin: ${(props) => props.margin};
  `}


`

export default Grid;