import React from "react";
import EnergyBarForm from "../components/EnergyBarForm";
import dayjs from "dayjs";

const EnergyBarDetail = ({myondoData}) => {

    // 날짜 (mon/24) 코드
    const start_time = myondoData?.data?.presence?.start_time
    
    // 에너지 절감 관련 그래프 코드
    const energy_reduction = myondoData?.data?.energy_reduction

    // 그래프의 layout 정보를 담는 변수
    let layout

    
    let beforeDate;
    let futureDate;
    let beforeTotalScore;
    let afterTotalScore;

    // 금일 총 에너지 절감 데이터 값을 담을 변수
    let energy_reduction_data;
    let myondo_energy_reduction_data;
    let space;

    //x축 범위(range)
    const x_range_start = -2.5
    const x_range_end = 3;

    //y축 범위(range)
    const y_range_start = 0;
    const y_range_end = 100;

    //y축 라벨 이름
    let labels = ['0','20','40','60', '80', '100']
    
    //y축 라벨 위치
    let labelsPosition = [0, 20, 40, 60, 80, 100]

 
        
    //data(x축의 값들과 y축의 값들을 포함한)
    let personData = [];
    
    // 현재 브라우저의 크기
    let now_width = window.innerWidth;
    
    // 그래프 폰트 size
    let graph_font_size;
  
    let title_font_size;
    let x_time_size;


    //그래프 현재 값 text 위치
 

    // 현재 날짜의 요일명과 일자를 보여주는 코드
    const today_number = dayjs(start_time).format('DD')
    const today_name = dayjs(start_time).format('ddd')
    // console.log('2@2', today_name)
    const today_number_name = today_number + ' | ' + today_name

    // 금일 총 에너지 절감 그래프 수치 코드
    const original_consumption = (energy_reduction?.original_consumption * 100)
    const myondo_consumption = (energy_reduction?.myondo_consumption * 100)

    beforeDate = [today_number_name]
    beforeTotalScore = [original_consumption]
    
    futureDate = [today_number_name]
    afterTotalScore = [myondo_consumption]
    // afterTotalScore = []

    // 반응형에 따라 선과 폰트의 크기 변화하는 문장
    if (now_width >= 3000) {
        graph_font_size = 21;

        title_font_size = 23;

        x_time_size = 23;
    } else if (now_width >= 2220 && now_width < 3000) {
        graph_font_size = 13;

        x_time_size = 14;
    ;
    }
    else if (now_width >= 1900 && now_width < 2220) {
        graph_font_size = 13;
 
        title_font_size = 14;
        x_time_size = 16;
    

    } else if (now_width >= 1280 && now_width < 1900) {
        graph_font_size = 13;
  
        title_font_size = 10;
        x_time_size = 12;
 
        // console.log('4')
}     
    
    //절감되기 전 데이터
    energy_reduction_data = {
        x: beforeDate,
        y: beforeTotalScore,
        type:"bar",
        width: 0.85,
        marker:{
            color:'#FF7474'
        }
        
    }

    //절감 전과 후 데이터 사이의 공간을 만들어주기 위한 bar
    space = {
        x: beforeDate,
        y: beforeTotalScore,
        type:"bar",
        width: 0.33,
        marker:{
            color:'rgb(23,23,23)'
        }
        
    }

    //절감 된 후 데이터
    myondo_energy_reduction_data = {
        x: futureDate,
        y: afterTotalScore,
        type:"bar",
        width: 0.75,
        marker:{
            color:'#00A7FF'
        }

    }

    // 그래프에 들어갈 데이터들을 모아놓음
    // TotalScoreForm에 있는 Plot으로 전달하기 위함
    personData.push( energy_reduction_data, space, myondo_energy_reduction_data);

    // 포인트 라벨에 값이 없을 때 'new text' 안 뜨게 하는 문장
    let valueY;
    
    if( isNaN(myondo_consumption)) {
        valueY = ' '
        
    } else {
        valueY = (original_consumption - myondo_consumption) +'% 절감'
    }
    
    // layout 코드
    layout = {
        title: '<b>금일 총 에너지 절감</b>', 
        bargap: 0,
        bargroupgap: 0,
        barmode: 'group',
        font : {
            'family' : 'Poppins',
            'color' : '#5F5F5F',
            'size' : title_font_size,
        
        },
        paper_bgcolor:'rgb(23,23,23)',
        plot_bgcolor:'rgb(23,23,23)', 
        // 자동으로 그래프 사이즈가 변하지 않게 하기 위함
        autosize: true, 
        // showlegned는 그래프의 곡선의 성격을 보여줌 (해당 프로젝트에서는 사용하지 않음)
        showlegend: false,
        // 마우스를 그래프 곡선에 가리켜도 해당 정보가 보이지 않도록 함
        hovermode: false,
        //margin으로 plot의 크기 설정
        margin: {
            
            l: 29,
            r: 19,
            b: 55,
            t: 71,
            pad: 4
        },
        
        // 그래프의 x축 layout을 담당
        xaxis: {
            // x축으로 볼 수 있는 범위
            range:[x_range_start,x_range_end],
            showline: false,
            showgrid: false,
            showticklabels: true,
            linecolor: 'rgb(71,71,71)',
            linewidth: 2,
            //autotick: false,
            ticks: '',
            tickfont: {
                family: 'NanumGothic',
                size: x_time_size,
            },
            fixedrange: true
        },
        // 그래프의 y축 layout을 담당
        yaxis: {
            range: [y_range_start, y_range_end],
            // y축으로 볼 수 있는 범위
            showgrid: false,
            gridcolor: 'rgb(71, 71, 71)',
            zeroline: false,
            showline: false,
            showticklabels: false,
            type: 'linear',
            fixedrange: true
            
        },
        
        shapes: [
            {
                type: 'line',
                x0: x_range_start,
                y0: 0,
                x1: x_range_start,
                y1: 100,
                line: {
                color: 'rgb(71, 71, 71)',
                width: 1.5,
                dash: 'line'
                },
                opacity: 0.6,  
                layer: 'below' 
            },
            {
                type: 'line',
                x0: 3,
                y0: 0,
                x1: 3,
                y1: 100,
                line: {
                color: 'rgb(71, 71, 71)',
                width: 1.5,
                dash: 'line'
                },
                opacity: 0.6,  
                layer: 'below' 
            },      
            //y축 line 라벨
        {
            type: 'line',
            x0: -10,
            y0: 0,
            x1: 47,
            y1: 0,
            line: {
            color: 'rgb(71, 71, 71)',
            width: 1.5,
            dash: 'line'
            },
            opacity: 0.6,  
            layer: 'below' 
        },
        {
            type: 'line',
            x0: -10,
            y0: 20,
            x1: 47,
            y1: 20,
            line: {
            color: 'rgb(71, 71, 71)',
            width: 1.5,
            dash: 'line'
            },
            opacity: 0.6,
            layer: 'below' 
        },
        {
            type: 'line',
            x0: -10,
            y0: 40,
            x1: 47,
            y1: 40,
            line: {
            color: 'rgb(71, 71, 71)',
            width: 1.5,
            dash: 'line'
            },
            opacity: 0.6,
            layer: 'below'   
        },
        {
            type: 'line',
            x0: -10,
            y0: 60,
            x1: 47,
            y1: 60,
            line: {
            color: 'rgb(71, 71, 71)',
            width: 1.5,
            dash: 'line'
            },
            opacity: 0.6,
            layer: 'below'   
        },
        {
            type: 'line',
            x0: -10,
            y0: 80,
            x1: 47,
            y1: 80,
            line: {
                color: 'rgb(71, 71, 71)',
                width: 1.5,
            dash: 'line'
            },
            opacity: 0.6,
            // shapes로 만든 y축 선을 data 아래에 배치되도록
            layer: 'below'  
        },
        {
            type: 'line',
            x0: -10,
            y0: 100,
            x1: 47,
            y1: 100,
            line: {
                color: 'rgb(71, 71, 71)',
                width: 1.5,
            dash: 'line'
            },
            opacity: 0.6,
            // shapes로 만든 y축 선을 data 아래에 배치되도록
            layer: 'below'  
        },    
        ],
        annotations: [
            // {
            //     // 현재 시간의 재실 수치를 보여주는 label (n% 현재재실)
            //     type: 'scatter',
            //     xref: 'x',
            //     yref: 'y',
            //     arrowhead: 2,
            //     arrowsize : 25,
            //     arrowcolor:'#171717',
            //     ax: 0,
            //     ay: pointLabel,
            //     x: valueY_position,
            //     y: today_number_name[myondo_consumption],
            //     text : valueY,
            //     // textposition: 'top right',
            //     font: {
            //         color:'#FFF500',
            //         size: graph_point_font_size,
            //     },
            //     showarrow: false,
            // },
        ],
    }

    //label을 반복문을 통해 그래프에 보여줌
    for( let i = 0 ; i < labels.length ; i++ ) {
    let result = {
        xref: 'paper',
        x: 0.006,
        y: labelsPosition[i],
        xanchor: 'right',
        yanchor: 'middle',
        text: `<b>${labels[i] + '  '}</b>`, 
        showarrow: false,
        font: {
            family: 'NanumGothic',
            size: graph_font_size,
            color: '#474747'
        }
    };


    layout.annotations.push(result);
    }

    return(
        <EnergyBarForm
            personData={personData}
            layout={layout}
            valueY={valueY}
            afterTotalScore={afterTotalScore}
        />
    )
}

export default EnergyBarDetail;