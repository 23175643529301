import React from "react";
import Plot from 'react-plotly.js'
import { Grid, Image, Text } from "../elements";
import speechBuble from '../assets/Icon/speech_bubble.png'

const PersonGraphForm = ({personData, layout, valueY, percent_y_array}) => {

    let now_width = window.innerWidth;

      // 말풍선 postion 
    
    let image_top;
    let image_left;
    let text_percent_top;
    let text_percent_left;
    let text_top;
    let text_left;

    // 말풍선 및 text 크기 조절
    let position_img_top = 18.606
    let position_text_percent_top = 19.350
    let position_text_top = 20.408
    
    let point_element 
    // percent_y_array[3] = 40
    if(percent_y_array[3] >= 0 && percent_y_array[3] <= 20 ) {
        point_element = 2.04
    }  else if(percent_y_array[3] >= 21 && percent_y_array[3] <= 40 ) {
        point_element = 1.94
    }
    else if(percent_y_array[3] >= 41 && percent_y_array[3] <= 70 ) {
        point_element = 1.84
    } else if(percent_y_array[3] >= 71 && percent_y_array[3] <= 100 ){
        point_element = 1.82
    }


    let point_position = (percent_y_array[3]* 0.1) * point_element


    if (now_width >= 3000) {
        image_top = (position_img_top - point_position)-0.5
        image_left = 33.797
        text_percent_top= (position_text_percent_top - point_position) -0.5
        text_percent_left = 35.955
        text_top = (position_text_top - point_position) - 0.5
        text_left = 35.0092
        
    } else if (now_width >= 2220 && now_width < 3000) {
        image_top = (position_img_top - point_position)
        image_left = 33.397
        text_percent_top= (position_text_percent_top - point_position)
        text_percent_left = 35.755
        text_top = (position_text_top - point_position)
        text_left = 34.8092
    
    } else if (now_width >= 1900 && now_width < 2220) {
        image_top = (position_img_top - point_position)
        image_left = 32.997
        text_percent_top= (position_text_percent_top - point_position)
        text_percent_left = 35.255
        text_top = (position_text_top - point_position)
        text_left = 34.4092

    } else if (now_width >= 1280 && now_width < 1900) {
        
        image_top = (position_img_top - point_position)
        image_left = 32.297
        text_percent_top= (position_text_percent_top - point_position)
        text_percent_left = 34.525
        text_top = (position_text_top - point_position)
        text_left = 33.6792
    }     


    return(
            <Grid width='100%' height='28.2vw' margin="0.8vw 0 0 0" bgColor ='rgb(23,23,23)' borderRadius= '13px'   >
                <Plot
                    data={personData}
                    layout={layout}
                    config={{
                        responsive : true,
                        displayModeBar : false
                    }}
                    
                    style = {{width: "97%", height: '28.2vw', useResizeHandler: true, margin:'0 auto' }}
                />
                { personData[1].x[0] === undefined ? null :
                <Image 
                    position='absolute'
                    src={speechBuble}
                    top = {image_top + 'vw' }
                    left= {image_left + 'vw' }
                    width='5.868vw'
                    height='4.054vw'
                />
                 }
                <Text
                fontFamily = 'Poppins'
                position="absolute"
                top={text_percent_top +'vw'}
                left={text_percent_left +'vw'}
                size="0.79vw"
                color="#FFF500"
                >
                    {valueY}
                </Text>
                { personData[1].x[0] === undefined ? null :
                <Text
                fontFamily = 'Poppins'
                position="absolute"
                top={text_top + 'vw'}
                left={text_left + 'vw'}
                size="0.79vw"
                color="#FFFFFF"
                >
                    현재재실
                </Text>
                }
            </Grid>
    )
}

export default PersonGraphForm;