import { generateMedia } from 'styled-media-query';

// tv크기에 맞게 수정해야 함

const customMedia = generateMedia({
    uhd: '3840px',
    qhd: '2560px',
    hd: '1920px',
    wxga: '1366px',
    tabletGalaxy : '1280px',
    tablet: '1180px',
    tabletMini : '1024px',
});

export default customMedia;