/* eslint-disable react/jsx-pascal-case */
/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { dataCreators } from '../redux/modules/dashboard';
import styled from 'styled-components';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import LeftButton from '../assets/Icon/LeftButton.svg';
import RightButton from '../assets/Icon/RightButton.svg';
import Dashboard from './Dashboard';

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        zIndex: '10',
        margin: '0px 0px 0px 26px',
        backgroundSize: '30px',
        backgroundRepeat: 'no-repeat',
      }}
      onClick={onClick}
    >
      <img width='65px' height='65px' src={LeftButton} />
    </div>
  );
}
function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        zIndex: '10',
        margin: '0px 70px 0px 0px',
        backgroundSize: '30px',
        backgroundRepeat: 'no-repeat',
      }}
      onClick={onClick}
    >
      <img width='65px' height='65px' src={RightButton} />
    </div>
  );
}

const DashboardSlick02 = (props) => {
  const dispatch = useDispatch();

  const DevicedashboardData = useSelector(
    (state) => state.data.dashboardData02?.data,
  );

  function delayTime() {
    return new Promise(() => {
      setTimeout(() => {
        window.location.reload();
      }, 10 * 60 * 1000);
    });
  }

  React.useEffect(() => {
    dispatch(dataCreators.getDashboard02MW());
    delayTime();

    let now_width = window.innerWidth;
    if (now_width <= 1040) {
      alert('현재 해상도 이하로 화면이 고르지 못함을 알려드립니다.');
    }
  }, [dispatch]);

  // slick settings
  let settings = {
    dots: false, // 스크롤바 아래 점으로 페이지네이션 여부
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true, // 옆으로 이동하는 화살표 표시 여부
    autoplay: true, // 자동 스크롤 사용 여부
    autoplaySpeed: 25000, // 자동 스크롤 시 다음으로 넘어가는데 걸리는 시간 (ms)
    pauseOnHover: false, // 슬라이드 이동	시 마우스 호버하면 슬라이더 멈추게 설정
    vertical: false, // 세로 방향 슬라이드 옵션
    prevArrow: <SamplePrevArrow />,
    nextArrow: <SampleNextArrow />,
    dotsClass: 'slick-dots', //아래 나오는 페이지네이션(점) css class 지정
    draggable: true, //드래그 가능 여부
  };

  return (
    <React.Fragment>
      <SlickSection>
        <Slider {...settings}>
          {DevicedashboardData?.map((p, idx) => {
            return <Dashboard {...p} key={idx} />;
          })}
        </Slider>
      </SlickSection>
    </React.Fragment>
  );
};

const SlickSection = styled.section`
  display: inline-block;
  width: 100%;
  height: 100%;
  overflow: hidden;
  .slick-arrow {
    &::before {
      content: '';
    }
    .slick-list {
      border-radius: 15px;
    }
  }
`;

export default DashboardSlick02;
