import React from "react";
import PersonHistoryForm from "../components/PersonHistoryForm";
import dayjs from "dayjs";

const PersonHistoryDetail = ({presence}) => {

    

    // 그래프의 layout 정보를 담는 변수
    let layout
    
    //재실 히스토리의 start_time, presence_percentage의 값을 담아주는 변수
    let beforeDate;
    let historyScore;

    //z값에 넣을 배열
    let totalhistory;

    
    //person_history_data의 data(값)을 담아줌
    let person_history_data
        
    //data(x축의 값들과 y축의 값들을 포함한)
    let personData = [];

    // airquality와 future의 길이(갯수)
    let myondo_length = presence?.length;
    // let future_length = dashboardData?.future.length;
    
    // 현재 브라우저의 크기
    let now_width = window.innerWidth;
    
    // 그래프 관련 size
    let graph_font_size;
    let label_size;
    let title_font_size;
    let x_time_size;



    
    // x축과 y축에 데이터에 관한 배열 변수
    let beforeWeek = ['일','월','화','수','목','금','토']
    let beforeTotalScore = [
        0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23
    ]

    let y_label_zero = 0

    // z축의 퍼센트에 따른 색상 진하기 변경
    let colorscaleValue = [
        [0.0, 'rgba(255,255,255,0.33)'],
        [0.0111111111111, 'rgb(198,235,255)'],
        [0.555555555555, 'rgb(104,203,255)'],
        [1.0, 'rgb(0,148,255)']
    ];
    
    
    
    let arr = [];
    let day;
    // let hour;
    let dayArr = [];
    // let hourArr =[];
 
    // 요일별로 구분하여 2차원 배열로 재실 데이터를 담아줌
    // totalhistory = Array.from(Array(Array), () => new Array(0).fill(false));
        totalhistory = Array(myondo_length).fill(null).map(()=> Array(0));
        
    // 히스토리 값 배치를 위한 변수 
    let j = 0;
 
    for (let i = 0; i < myondo_length; i++){
        //서버에서 날짜 받아오는 코드
        beforeDate = presence[i]?.start_time;

        // 서버에서 받아온 날짜 형식 바꾸는 코드
        day = dayjs(beforeDate).format('dddd');
        dayArr.push(day)

        // 현재까지의 값과 앞으로 예상되는 값
        
        historyScore = presence[i]?.presence_percentage;
        arr.push(historyScore)
    
        if(dayArr[i] ==='Sunday'){
            if(j > 23){
                j = 0
            }
            totalhistory[j][0] = arr[i];

        }
        else if(dayArr[i] === 'Monday') {
            if(j > 23){
                j = 0
            }
            totalhistory[j][1] = arr[i];
        }
        else if(dayArr[i] ==='Tuesday'){
            if(j > 23){
                j = 0
            }
            totalhistory[j][2] = arr[i];            
        }
        else if(dayArr[i] ==='Wednesday'){
            if(j > 23){
                j = 0
            }            
            totalhistory[j][3] = arr[i];
            
        }else if(dayArr[i] === 'Thursday'){
            if(j > 23){
                j = 0
            }
            totalhistory[j][4] = arr[i];

        }else if(dayArr[i] === 'Friday'){
            if(j > 23){
                j = 0
            }
            totalhistory[j][5] = arr[i];

        }else if(dayArr[i] === 'Saturday'){
            if(j > 23){
                j = 0
            }
            totalhistory[j][6] = arr[i];

        }

        j++;
        
    }

    // 반응형에 따라 선과 폰트의 크기 변화하는 문장
    if (now_width >= 3000) {
        graph_font_size = 21;
        label_size = 7;
        title_font_size = 23;    
        x_time_size = 23;
    
    } else if (now_width >= 2220 && now_width < 3000) {
        graph_font_size = 13;
        label_size = 5.5;
        title_font_size = 18;
        x_time_size = 14;
    
    }
    else if (now_width >= 1900 && now_width < 2220) {
        graph_font_size = 12;
        label_size = 5.5;
        title_font_size = 14;
        x_time_size = 16;
        
    } else if (now_width >= 1280 && now_width < 1900) {
        graph_font_size = 5;
        label_size = 5.5;
        title_font_size = 10;
        x_time_size = 12;

    }     
    

    // 현재(person_history_data)까지의 수치를 보여줌
    person_history_data = {
        x: beforeWeek,
        y: beforeTotalScore,
        z: totalhistory,
        colorscale: colorscaleValue,
        type:"heatmap",
        showscale: false

        
    }


    // 그래프에 들어갈 데이터들을 모아놓음
    // TotalScoreForm에 있는 Plot으로 전달하기 위함
    personData.push(person_history_data);


    for(let i = 0; i<=23; i++) {
    
    // layout 코드
    layout = {
    
        title: '<b>주간 | 시간대별 재실 히스토리</b>', 
        bargap: 0.05,
        bargroupgap: 0.3,
        barmode: 'group',
        font : {
            'family' : 'Poppins',
            'color' : '#5F5F5F',
            'size' : title_font_size,
        
        },
        paper_bgcolor:'rgb(23,23,23)',
        plot_bgcolor:'rgb(23,23,23)', 
        // 자동으로 그래프 사이즈가 변하지 않게 하기 위함
        autosize: true, 
        // showlegned는 그래프의 곡선의 성격을 보여줌 (해당 프로젝트에서는 사용하지 않음)
        showlegend: false,
        // 마우스를 그래프 곡선에 가리켜도 해당 정보가 보이지 않도록 함
        hovermode: false,
        //margin으로 plot의 크기 설정
        margin: {
            
            l: 29,
            r: 19,
            b: 55,
            t: 71,
            pad: 4
        },
        
        // 그래프의 x축 layout을 담당
        xaxis: {
        // x축으로 볼 수 있는 범위
                showgrid: false,
                ticks: '',
                tickfont: {
                    family: 'Poppins',
                    size: x_time_size,
                },
        },
        // 그래프의 y축 layout을 담당
        yaxis: {
            range: [beforeTotalScore[0]-0.5, beforeTotalScore[beforeTotalScore.length-1]+0.5],
        // y축으로 볼 수 있는 범위
            showgrid: false,
            gridcolor: 'rgb(71, 71, 71)',
            zeroline: false,
            showline: false,
            showticklabels: false,
            type: 'linear',
            fixedrange: true,
            ticks: '',
            tickfont: {
                family: 'Poppins',
                size: label_size,
            },
        },
        
        shapes: [
            {
                type: 'line',
                x0: 0.5,
                y0: -0.5,
                x1: 0.5,
                y1: 24,
                line: {
                color: 'rgb(71, 71, 71)',
                width: 1.5,
                dash: 'line'
                },
                opacity: 0.6,  
                // layer: 'below' 
            },
            {
                type: 'line',
                x0: 1.5,
                y0: -0.5,
                x1: 1.5,
                y1: 24,
                line: {
                color: 'rgb(71, 71, 71)',
                width: 1.5,
                dash: 'line'
                },
                opacity: 0.6,  
                // layer: 'below' 
            },
            {
                type: 'line',
                x0: 2.5,
                y0: -0.5,
                x1: 2.5,
                y1: 24,
                line: {
                color: 'rgb(71, 71, 71)',
                width: 1.5,
                dash: 'line'
                },
                opacity: 0.6,  
                // layer: 'below' 
            }, 
            {
                type: 'line',
                x0: 3.5,
                y0: -0.5,
                x1: 3.5,
                y1: 24,
                line: {
                color: 'rgb(71, 71, 71)',
                width: 1.5,
                dash: 'line'
                },
                opacity: 0.6,  
                // layer: 'below' 
            }, 
            {
                type: 'line',
                x0: 4.5,
                y0: -0.5,
                x1: 4.5,
                y1: 24,
                line: {
                color: 'rgb(71, 71, 71)',
                width: 1.5,
                dash: 'line'
                },
                opacity: 0.6,  
                // layer: 'below' 
            }, 
            {
                type: 'line',
                x0: 5.5,
                y0: -0.5,
                x1: 5.5,
                y1: 24,
                line: {
                color: 'rgb(71, 71, 71)',
                width: 1.5,
                dash: 'line'
                },
                opacity: 0.6,  
                // layer: 'below' 
            }, 
        
        ],
        annotations: [

        ],
    }
}





    //label을 반복문을 통해 그래프에 보여줌
    for( let i = 0 ; i < beforeTotalScore.length ; i++ ) {
    let result = {
        xref: 'paper',
        x: 0.006,
        y: beforeTotalScore[i]+0.5,
        xanchor: 'right',
        yanchor: 'middle',
        text: `<b>${(beforeTotalScore[i] + 1) + '  '}</b>`, 
        showarrow: false,
        font: {
            family: 'Poppins',
            size: graph_font_size,
            color: '#474747'
        }
    };


    let line_X = {
        type: 'line',
        x0: -0.5,
        y0: beforeTotalScore[i]+0.5,
        x1: 6.5,
        y1: beforeTotalScore[i]+0.5,
        line: {
        color: 'rgb(71, 71, 71)',
        width: 1.5,
        dash: 'line'
        },
        opacity: 0.6,  
        // layer: 'below' 
    };

    layout.annotations.push(result);

    //y축 line 라벨
    layout.shapes.push(line_X);
    }

    let result_y_zero = {
        xref: 'paper',
        x: 0.006,
        y: -0.5,
        xanchor: 'right',
        yanchor: 'middle',
        text: `<b>${y_label_zero + '  '}</b>`, 
        showarrow: false,
        font: {
            family: 'Poppins',
            size: graph_font_size,
            color: '#474747'
        }
    }

    layout.annotations.push(result_y_zero);

    return(
        <PersonHistoryForm
        personData={personData}
        layout={layout}
        />
    )
}

export default PersonHistoryDetail;